import React, {useState} from 'react';
import {View, Text, StyleSheet} from "react-native";
import {SimpleTask} from "../../../../types";
import {Checkbox} from "react-native-paper";

interface TaskItemProps {
    onPress: () => void,
    onCheckboxPress: (state: boolean) => void,
    task: SimpleTask
}

function TaskItem({onPress, onCheckboxPress, task}: TaskItemProps) {
    const [checked, setChecked] = useState(task.isDone);

    return (
        <View style={styles.container}>
            <Checkbox
                status={checked ? 'checked' : 'unchecked'}
                onPress={() => {
                  onCheckboxPress(!checked);
                    setChecked(!checked);
                }}
            />
            <Text onPress={onPress}>{task.name}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default TaskItem;
