export enum TaskType {
    'SimpleTask',
    'AmountTask'
}


export interface Project {
    name: string;
    description: string;
    stages: Stage[]
    _id: string;
}

export interface Stage {
    name: string;
    tasks: (AmountTask | SimpleTask)[];
    isDone: boolean;
    _id: string;
}

export interface AmountTask {
    _id: string;
    name: string;
    type: TaskType.AmountTask;
    goal: number;
    current: number;
    isDone: boolean;
}

export interface SimpleTask {
    _id: string;
    name: string;
    type: TaskType.SimpleTask;
    isDone: boolean;
}

