import {StatusBar} from 'expo-status-bar';
import {PaperProvider} from "react-native-paper";
import {NavigationContainer} from "@react-navigation/native";
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import NavigationComponent from "./src/components/NavigationComponent";

const queryClient = new QueryClient();

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <PaperProvider>
                {/*<Stack.Navigator>*/}
                {/*    <Stack.Screen name="Home" component={ProjectListScreen} />*/}
                {/*    <Stack.Screen name="Profile" component={ProfileScreen} />*/}
                {/*    <Stack.Screen name="Project" component={ProjectScreen} />*/}
                {/*</Stack.Navigator>*/}
                <NavigationContainer>
                    <NavigationComponent />
                </NavigationContainer>
            </PaperProvider>
        </QueryClientProvider>
    );
}
