import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {BottomNavigation} from 'react-native-paper';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {CommonActions} from "@react-navigation/native";
import ProjectListScreen from "./screens/ProjectListScreen/ProjectListScreen";
import ProfileScreen from "./screens/ProfileScreen/ProfileScreen";
import ProjectScreen from "./screens/ProjectScreen/ProjectScreen";

const Tab = createBottomTabNavigator();

export default function NavigationComponent() {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
      }}
      tabBar={({navigation, state, descriptors, insets}) => (
        <BottomNavigation.Bar
          navigationState={state}
          safeAreaInsets={insets}
          onTabPress={({route, preventDefault}) => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (event.defaultPrevented) {
              preventDefault();
            } else {
              navigation.dispatch({
                ...CommonActions.navigate(route.name, route.params),
                target: state.key,
              });
            }
          }}
          renderIcon={({route, focused, color}) => {
            const {options} = descriptors[route.key];
            if (options.tabBarIcon) {
              return options.tabBarIcon({focused, color, size: 24});
            }
            return null;
          }}
          getLabelText={({route}) => {
            const {options} = descriptors[route.key];
            return options.tabBarLabel as string;
          }}
        />
      )}
    >
      <Tab.Screen
        name="Projects"
        component={ProjectListScreen}
        options={{
          tabBarLabel: 'Projects',
          // tabBarIcon: ({color, size}) => {
          //   return <Icon name="cash" size={size} color={color}/>;
          // },
        }}
      />
      <Tab.Screen
        name="Project"
        component={ProjectScreen}
        options={{
          // tabBarLabel: 'Projects',
          // tabBarIcon: ({color, size}) => {
          //   return <Icon name="cash" size={size} color={color}/>;
          // },
        }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          tabBarLabel: 'Profile',
          // tabBarIcon: ({color, size}) => {
          //   return <Icon name="database" size={size} color={color}/>;
          // },
        }}
      />
    </Tab.Navigator>
  );
}
