import React from 'react';
import {StyleSheet, View, Text} from "react-native";
import { useQuery } from '@tanstack/react-query';
import {Project} from "../../../../types";
import {getProjects} from "../../../db";

export default function ProfileScreen({navigation}) {
  const { isPending, error, data } = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: getProjects,
  })

  if (!data) {
    return (
      <View>
        {isPending && (
          <Text>Fetching user data...</Text>
        )}
        {error && (
          <Text>{`Error get data!!!`}</Text>
        )}
      </View>
    )
  }

  const getPoints = () => {
    let points = 0;
    data.forEach(project => {
      points += project.stages.filter(stage => stage.isDone === true).length
    })
    return points;
  }

  return (
    <View style={styles.container}>
      <Text>{getPoints()}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 8,
    gap: 12,
  },
  progressContainer: {
    backgroundColor: 'lightpink', flexGrow: 1
  },
  progressChild: {
    height: 10, backgroundColor: 'green'
  }
});
