import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Text} from "react-native-paper";
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import ProgressBar from "./ProgressBar";
import {Project} from "../../../../types";

type ProjectItemProps = {
  project: Project,
  onPress: () => void,
}

function ProjectItem({project, onPress, ...rest}: ProjectItemProps) {
  const [currentStage, setCurrentStage] = useState(-1)
  const stagesDone = useMemo(() => {
    return project.stages.filter(stage => stage.isDone).length;
  }, [project])

  useEffect(() => {
    if (!project.stages) {
      return;
    }
    setCurrentStage(project.stages.findIndex(stage => !stage.isDone));
  }, [project]);

  return (
    <TouchableOpacity onPress={onPress} style={{...styles.container}} {...rest}>
      <Text variant="titleLarge">{project.name}</Text>
      {currentStage !== -1 ? (
        <Text
          variant="titleMedium">LVL {currentStage + 1}/{project.stages.length} - {project.stages[currentStage].name}</Text>

      ) : (
        <Text variant="titleMedium">No stages defined yet</Text>
      )}
      <ProgressBar
        style={styles.progress}
        stagesDone={stagesDone}
        tasks={currentStage !== -1 ? project.stages[currentStage].tasks : []}/>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 120,
    minWidth: 400,
    flexGrow: 1,
    flexBasis: 33,
    padding: 16,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'grey',
    borderStyle: 'solid',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  progress: {
    flexGrow: 1,
  }
});

export default ProjectItem;
