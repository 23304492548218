import axios from "axios";
import {Project} from "../types";

const dbUrl = 'http://localhost:8000'
export const getProjects = () => {
    return axios.get(`${dbUrl}/projects`)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log(error);
        });
}

export const getProject = (id) => {
    return axios.get(`${dbUrl}/projects/${id}`)
        .then(response => {
            console.log(response.data)
            return response.data
        })
        .catch(error => {
            console.log(error);
        });
}

export const createProject = (data: Omit<Project, '_id'>): Promise<Project> => {
    return axios.post(`${dbUrl}/projects`, data)
        .then(response => {
            return response.data
        })
        .catch(error => {
        });
}

export const updateProject = (data) => {
    return axios.put(`${dbUrl}/projects/${data._id}`, data)
        .then(response => {
            return response.data
        })
        .catch(error => {
        });
}
