import React from 'react';
import {Project} from "../../../../types";
import ProjectItem from "./ProjectItem";
import {StyleSheet, View, Text, ScrollView} from "react-native";
import {getProjects, createProject} from "../../../db";
import ProjectAdd from "./ProjectAdd";
import { useQuery } from '@tanstack/react-query';
import {useMutation, useQueryClient} from "@tanstack/react-query";

function ProjectListScreen({navigation}) {
    const queryClient = useQueryClient();

    const { isPending, error, data } = useQuery<Project[]>({
        queryKey: ['projects'],
        queryFn: getProjects,
    })

    const createMutation = useMutation({
        mutationFn: createProject,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['projects'] })
              .then(() => navigation.navigate('Project', {
                  itemId: data._id,
                  newProject: true,
              }))
        },
    })

    const handleNewProjectClick = () => {
        createMutation.mutate({
            name: 'New project',
            description: '',
            stages: [],
        });
    }

    const handleProjectClick = (id: string) => () => {
        navigation.navigate('Project', {
            itemId: id,
        });
    }

    return (
        <ScrollView contentContainerStyle={styles.container}>
            {isPending && (
                <Text>Fetching user data...</Text>
            )}
            {error && (
                <Text>{`Error get data!!!`}</Text>
            )}
            <View style={styles.projectList}>
                {data && data.map(project => (
                  <ProjectItem
                    key={project._id}
                    onPress={handleProjectClick(project._id)}
                    project={project}
                  />))}
            </View>
            <ProjectAdd handleClick={handleNewProjectClick}/>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        padding: 8,
    },
    projectList: {
        display: "flex",
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 12,
    },
    progressContainer: {
        backgroundColor: 'lightpink', flexGrow: 1
    },
    progressChild: {
        height: 10, backgroundColor: 'green'
    }
});

export default ProjectListScreen;
