import {ScrollView, StyleSheet, TouchableOpacity, View} from "react-native"
import {getProjects, updateProject} from "../../../db";
import {Button, Text} from 'react-native-paper';
import {Project, Stage, TaskType} from "../../../../types";
import TaskItem from "./TaskItem";
import TaskInput from "./TaskInput";
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {useCallback, useMemo, useState} from "react";
import EditInput from "./EditInput";


export default function ProjectScreen({route, navigation}) {
  const {itemId, newProject = false} = route.params;
  const queryClient = useQueryClient();

  const {isPending, error, data} = useQuery<Project[]>({
    queryKey: ['projects'],
    queryFn: () => getProjects(),
  })

  const updateMutation = useMutation({
    mutationFn: updateProject,
    onSuccess: () => {
      console.log('on success');
      queryClient.invalidateQueries({queryKey: ['projects']})
    },
  })

  const update = (project: Project) => {
    updateMutation.mutate(project);
  }

  if (!data) {
    return (
      <View>
        {isPending && (
          <Text>Fetching user data...</Text>
        )}
        {error && (
          <Text>{`Error get data!!!`}</Text>
        )}
      </View>
    )
  }

  const [titleEditMode, setTitleEditMode] = useState(newProject);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);
  const [editedStageTitleIndex, setEditedStageTitleIndex] = useState(-1);
  //stageindex, taskindex
  const [editedTaskId, setEditedTaskId] = useState([-1, -1]);
  const project = data.find(dat => dat._id === itemId) as Project

  const shouldShowCompleteStageButton = useCallback((stage: Stage) => {
    return stage.tasks.length !== 0 && !stage.isDone && stage.tasks.filter(task => !task.isDone).length === 0;
  }, [project])

  const handleTaskPress = (task) => (data) => {
    task.isDone = data;
    update(project);
  }

  const handleNewStage = () => {
    project.stages.push({
      name: 'new Stage',
      tasks: [],
      isDone: false,
    })
    update(project);
  }

  const handleNewTask = (index) => (name) => {
    project.stages[index].tasks.push({
      name: name,
      type: TaskType.SimpleTask,
      isDone: false,
    })
    update(project);
  }
  const handleCompleteStage = (index) => () => {
    project.stages[index].isDone = true;
    update(project);
  }

  const handleTitleChange = (text: string) => {
    project.name = text.trim() || 'New Project';
    setTitleEditMode(false);
    update(project);
  }

  const handleDescriptionChange = (text: string) => {
    project.description = text;
    setDescriptionEditMode(false);
    update(project);
  }

  const handleStageChange = (i) => (text: string) => {
    project.stages[i].name = text;
    setEditedStageTitleIndex(-1);
    update(project);
  }

  const handleTaskChange = (stageIndex, taskIndex) => (text: string) => {
    project.stages[stageIndex].tasks[taskIndex].name = text;
    setEditedTaskId([-1, -1]);
    update(project);
  }

  const handleStagePress = (stage) => (e) => {
    console.log(e);
    console.log(stage);
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View>
        {!titleEditMode ? (
          <Text onPress={() => setTitleEditMode(true)} variant="headlineLarge">{project.name}</Text>
        ) : (
          <EditInput
            onSave={handleTitleChange}
            defaultText={project.name}
            autoFocus
            placeholder='New Project'
            autoSelectText
            saveOnBlur
          />
        )}

        {!descriptionEditMode ? (
          <Text onPress={() => setDescriptionEditMode(true)} style={styles.description}>{project.description}</Text>
        ) : (
          <EditInput
            style={styles.description}
            onSave={handleDescriptionChange}
            defaultText={project.description}
            autoFocus
            saveOnBlur
            saveOnEnter={false}
          />
        )}

        {project.stages.map((stage, stageIndex) =>
          <TouchableOpacity
            key={stage._id}
            onPress={handleStagePress(stage)}
            style={{...styles.stage, ...(stage.isDone ? styles.stageDone : {cursor: 'default'})}}
          >
            <View style={{width: 100, borderColor: 'black', borderRightWidth: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Text style={styles.stageLevelText}>{stageIndex + 1}</Text>
            </View>
            <View style={{padding: 12}}>
              <View style={{marginBottom: 10}}>
                {stageIndex !== editedStageTitleIndex ? (
                  <Text onPress={() => setEditedStageTitleIndex(stageIndex)} variant="headlineSmall">{stage.name}</Text>
                ) : (
                  <EditInput
                    defaultText={stage.name}
                    saveOnBlur
                    autoFocus
                    autoSelectText
                    onSave={handleStageChange(stageIndex)}/>
                )}
              </View>

              <View>
                {stage.tasks.map((task, taskIndex) => {
                  if (stageIndex === editedTaskId[0] && taskIndex === editedTaskId[1]) {
                    return (
                      <TaskInput
                        key={task._id}
                        defaultText={task.name}
                        onSave={handleTaskChange(stageIndex, taskIndex)}/>
                    )
                  }
                  return <TaskItem key={task._id} onCheckboxPress={handleTaskPress(task)}
                                   onPress={() => setEditedTaskId([stageIndex, taskIndex])} task={task}/>
                })}
                {!stage.isDone && <TaskInput onSave={handleNewTask(stageIndex)}/>}
              </View>

              {shouldShowCompleteStageButton(stage) &&
                <Button onPress={handleCompleteStage(stageIndex)}>Complete</Button>}
            </View>
          </TouchableOpacity>
        )}

        <TouchableOpacity onPress={handleNewStage} style={{...styles.stage, ...styles.newStage}}>
          <Text style={{fontSize: 40}}>+</Text>
        </TouchableOpacity>

      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    display: 'flex',
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'stretch',
  },
  description: {
    height: 180,
  },
  stage: {
    marginTop: 12,
    borderWidth: 2,
    borderColor: 'grey',
    borderStyle: 'solid',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
  },
  stageDone: {
    backgroundColor: '#8a8',
    borderColor: 'green',
  },
  newStage: {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    height: 80,
  },
  stageLevelText: {
    borderWidth: 2,
    borderRadius: 50,
    borderColor: 'black',
    height: 40,
    width: 40,
    textAlign: "center",
    lineHeight: 40,
    fontSize: 20,
  }
});
