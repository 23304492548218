import React from 'react';
import {StyleSheet, View} from "react-native";
import {Text} from "react-native-paper";

interface ProgressBarProps {
    tasks: any[],
    stagesDone: number,
}
function ProgressBar({tasks, stagesDone}: ProgressBarProps) {
    const calculateProgress = () => {
        const tasksAmount = tasks.length;
        let tasksDone = tasks.reduce((sum, task) => {
            let val;
            if (task.isDone !== undefined) val = task.isDone ? 1 : 0;
            else val = task.current / task.goal;

            return sum + val;
        }, 0);

        return tasksDone / tasksAmount;
    }

    return (
        <View style={styles.container}>
            <Text style={styles.stagesDone}>{stagesDone}</Text>
            <View style={styles.progressContainer}>
                <View style={{...styles.progressChild, ...(tasks.length === 0 && styles.emptyProgress), width: `${calculateProgress() * 100}%`}}></View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    progressContainer: {
        backgroundColor: 'lightgray',
        flexGrow: 1,
        marginLeft: -2,
        zIndex: -1,
    },
    progressChild: {
        height: 10, backgroundColor: 'green',
    },
    emptyProgress: {
        backgroundColor: 'gray',
    },
    stagesDone: {
        borderWidth: 2,
        borderRadius: 50,
        borderColor: 'black',
        height: 40,
        width: 40,
        textAlign: "center",
        lineHeight: 40,
        fontSize: 20,
    }
});

export default ProgressBar;
