import React from 'react';
import {View, StyleSheet} from "react-native";
import {Checkbox} from "react-native-paper";
import EditInput, {EditInputProps} from "./EditInput";

interface TaskInputProps extends EditInputProps {
    onSave: (name: string) => void,
}

function TaskInput({onSave, ...rest}: TaskInputProps) {
    return (
        <View style={styles.container}>
            <Checkbox
                status='unchecked'
                disabled={true}
            />
          <EditInput
            onSave={onSave}
            placeholder={'New task...'}
            {...rest}
          />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export default TaskInput;
