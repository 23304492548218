import React, {useEffect, useRef, useState} from 'react';
import {View, StyleSheet, TextInputKeyPressEventData, NativeSyntheticEvent} from "react-native";
import {Button, TextInput} from "react-native-paper";
import {TextInput as NativeTextInput} from "react-native/Libraries/Components/TextInput/TextInput";

export interface EditInputProps extends NativeTextInput {
  onSave: (text: string) => void,
  defaultText?: string,
  saveOnBlur?: boolean
  autoSelectText?: boolean
  saveOnEnter?: boolean
}

export default function EditInput({
                                    onSave,
                                    defaultText = '',
                                    saveOnBlur = false,
                                    autoSelectText = false,
                                    saveOnEnter = true,
                                    ...rest
                                  }: EditInputProps) {
  const [text, setText] = useState(defaultText);

  const input = useRef<NativeTextInput>(null);
  useEffect(() => {
    if (autoSelectText && input.current) input.current.setSelection(0, -1);
  }, [input]);
  const submit = () => {
    onSave(text);
    setText('')
  }

  const handleKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    console.log(saveOnEnter)
    if (saveOnEnter && event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  }

  const handleBlur = () => {
    if (saveOnBlur) submit();
  }

  return (
    <View style={styles.container}>
      <TextInput
        ref={() => input}
        mode="outlined"
        onBlur={handleBlur}
        onChangeText={setText}
        value={text}
        onKeyPress={handleKeyPress}
        // selection={autoSelectText ? {start: 0, end: -1} : undefined}
        {...rest}
      />
      <Button mode="contained-tonal" onPress={submit}>+</Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
