import React from 'react';
import {Button, Card, Text} from "react-native-paper";
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {Project} from "../../../../types";

type ProjectAddProps = {
    handleClick: () => void
}

function ProjectAdd({handleClick}: ProjectAddProps) {
    return (
        <TouchableOpacity onPress={handleClick} style={styles.container}>
            <Text style={{fontSize: 40}}>+</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    container: {
        height: 120,
        minWidth: 500,
        flexGrow: 1,
        borderWidth: 1,
        borderColor: 'grey',
        borderStyle: 'solid',
        borderRadius: 12,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default ProjectAdd;
